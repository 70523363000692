import React from "react"
import { Link, graphql, navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const to = () => {
  navigate("/estado/quintanaroo/")
}

const IndexPage = ({data}) => (
  <Layout>
    <div 
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url(${data.mapBackground.childImageSharp.fluid.src})`,
        backgroundSize: "cover",
        backgroundPositionY: "-100px"
      }}
    >
      <Img style={{width: '25%', alignSelf: 'flex-end', marginBottom: '6rem'}} fluid={data.discover.childImageSharp.fluid} />
      <svg className="map" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 1117.1 773" style={{enableBackground: "new 0 0 1117.1 773",}} xmlSpace="preserve">
   
        <g className="state" id="baja_california_sur">
          <path class="st0" d="M280.4,401.5l-2.2-7.9l-3.3-3.3l1.3-3.3l-1.5-2.3l-2,0.8l-2.3,0.1l-0.6-3.2l-1.8-3.3l-2.9-0.6l-1.9-2.7
            l-2.5-3.8l-2.2,0.4l-0.4,2.7l0.9,2.8l-1.7,1.4l-2.6,1.7l-1.3-1.9l-2.9-0.8l-5.6-4.6l-1.4-3.9l-1.4-7.8l2.4-8.2l-4-9.1l-2.2-1.1
            l-6.6-12.3l-0.4-4.6l-3.4-1.5l-1.3-2.8l-3.6-5.6l-4.1-7.4l1.5-6.1v-2.3l-1.8-4l0.1-3.8l-3.2-2.4l0.8-3.7l-3.3-1.5l-1.1-7.7
            l-8.8-7.4l-2.3,2l2.3,5.8l4.9,4.2l-2.6,1.5l-6-6.4l0.4-3.1l-1.4-3.2l-2.4-4.5c0,0,2.3-2.3,2.4-2.8c0.1-0.5-8.6-2.8-8.6-2.8
            l-0.3-2.7l-3.4-2.9l-1.4-8.6l-8.6-5.9l-5.9-6l0.6-6.5h-53.3l-2.9,1l-0.8,1.8l1.4,2.7l1.7,3.1l2.4,0.8l2.6-0.8l2.4,0.8l-0.1,1.9
            l-3.3-0.8l-3.8,0.6l-3.1-1.4l-1-4.5l-2.4,0.4l-3.6,2.6c0,0-20.1-3.6-20.4-3.7c-0.4-0.1,1.3,4.6,1.3,4.6l4.6,1.9l6.1,6.8l5.5,1.9
            l3.2,9.7l6.3,2.4l6.3,0.6l3.4,6.3l6.1,0.4l5.4,6.4l4,2.9l1.7,0.6l5-4.5l5.2,3.3l1.7-2.9l1.9-1.9l0.8-2.7l1-0.1l-0.3,4.3l-3.2,2.2
            l0.1,3.1l4.2,1.7l15.2,13.1l6.6,2.2l4,0.5l1.8,6.6l2,1.3l6.4,10.9l1.5,19.5l-1.4,4.3l-1,7.8l2,4.5h2.9l4.6,8.8l2,3.7l4.3,1.3l1,0.8
            l3.6,4.3l20.7,13.7l2.8,4l4.1,1.7l6.4,9.5l9.3,1.7l8.6,18.4l2.9,10.3l3.8,2.4l7.3-4.2L289,419l1.3-11.5l-4.5-5.2L280.4,401.5z"/>
          
            <image className="state-image" width="206" height="205" xlinkHref={data.baja_california_sur.childImageSharp.fluid.src}  transform="matrix(1.0151 0 0 1.0151 84.31 223.0244)">
          </image>
        </g>
        <g className="state" id="baja_california">
          <polygon class="st0" points="99.7,182.1 105.2,189.6 107.1,190.4 115.1,199.5 118.9,200 119.3,203 123.1,205.8 117.9,217.6 
            120.8,219.9 120.4,224.2 120.1,225.9 168.1,225.9 168.4,221 165.9,215.9 165.1,210.3 161.2,211.7 156.2,210.1 155.4,199.8 
            152.2,194.7 149.7,196.3 147.8,191.2 144.9,190.4 142.8,193.7 141.2,190.1 142.9,186.2 137,179.8 137.7,177 117.7,157.6 
            113.8,157.4 111,156.7 110.8,151.7 107.4,150.2 101.8,140.7 100.5,127 99.3,124.9 98.5,110.8 94,108.3 95.7,105 93.6,103 
            94.2,87.6 95.3,85.4 96.5,82.9 96.7,80 87.4,71.5 87.4,59.1 93.4,49 96,47.5 96.8,44.2 99,40.8 12.7,47.6 11.4,47.6 11.4,50.2 
            15.8,57.6 19.8,61.7 21.6,69.7 24.8,69.6 25.9,71 30.3,74.4 30.8,77.5 28.9,78.6 29,83.2 27.8,87.1 34,90.1 40.9,100.5 40.2,109.4 
            43.9,111.1 51.3,116.6 50.8,129.1 54.1,129.5 54,131.8 55.9,132.2 59.4,133.8 59.5,149.4 63.3,152 63.8,158.1 81.5,171 89.4,172.4 
              "/>
          
            <image className="state-image" width="176" height="193" xlinkHref={data.baja_california.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 5.0741 39.0244)">
          </image>
        </g>
        <g className="state" id="sonora">
          <polygon class="st0" points="95.3,50.9 90,59.8 90,70.4 95.7,75.7 96.9,76.2 99.7,77.5 103.7,77.9 108.3,81.2 117.5,87.8 
            122.6,88.6 124.8,87.1 125.5,82.4 127.6,83.8 137.4,89 139.2,95.3 148.8,97.4 156.6,99.6 159.5,102.8 158.5,107 156.3,114.7 
            157.4,119.8 165.8,132 166.9,137 169.2,139.3 169.8,149.1 172.6,150.8 173.6,154.9 182.2,166.3 183.8,170 183,173.4 185.2,174.8 
            187.7,175.3 189.8,177.7 190.1,179.8 191,186.8 192.8,188.6 197.3,193.3 200.6,195.1 200.9,197 198.8,197.2 200.4,198.7 
            205.3,204.7 207,208.5 212.5,210.6 216.4,213.1 217.4,216.2 226.3,226.9 229.7,229.5 232.4,228.7 234.7,231.3 237.8,232.3 
            239.2,229.9 241.6,231 248.1,232.4 248.1,234.3 247.4,236.5 248.4,238.4 245.8,241.5 247.4,246.3 248.9,252.6 253.5,256.4 
            258.9,260.1 267.7,261.9 270.2,263.6 272.8,271.2 276.4,278.2 280.4,279.8 284.1,277.4 287,277.2 295.4,285.9 296.1,290.8 
            298.5,291.2 322.1,274 322.1,269 315.7,262.6 316,247.3 311.5,243.5 309.7,236.9 304.5,232.9 297.3,219.9 302.2,213.9 315.9,217.3 
            318.4,215 313.8,199.7 316.7,196.2 313.8,168.7 317,168.7 317.5,142.7 312.4,122.7 312.4,102.2 308.4,103.6 308.9,95.8 231.2,95.8 
            95.9,50.5 	"/>
          
            <image className="state-image" width="235" height="250" xlinkHref={data.sonora.childImageSharp.fluid.src}  transform="matrix(1.0151 0 0 1.0151 86.7995 44.8578)">
          </image>
        </g>
        <g className="state" id="chihuahua">
          <polygon class="st0" points="311.2,99.9 314.9,98.7 314.9,122.4 320.1,142.4 319.5,171.3 316.6,171.3 319.4,197.1 316.6,200.3 
            321.3,215.8 316.6,220.1 303.1,216.8 300.3,220.2 306.4,231.2 312,235.4 313.8,242.1 318.5,246.1 318.3,261.5 323.7,266.9 
            330.3,263.8 341.4,267.6 341.4,275 348.7,280.3 350.5,298 366.8,302.3 368.7,310.3 375.8,320.8 375.7,321.4 387.3,321.4 
            393.2,315.9 393.2,305.2 398.6,301.7 399.5,299.3 395.3,295.3 394.5,290.3 403.3,290.3 406.5,282.3 406.5,275.3 412.6,272.9 
            421.1,278.8 427.3,280.4 437.4,287 443.3,284.3 459.7,287.8 463.9,291.3 466.4,291.3 477.8,274.9 497.8,280.5 484.9,233.9 
            511.5,185.4 501.8,180.3 494.5,178.7 494.5,176.4 480,170.8 478.4,166.4 470.7,164.6 460.9,146.9 463.4,144 463.4,139.6 
            456.8,135.3 456.8,127.6 448.9,119.6 432.7,111.7 429.1,105.1 416.4,93.3 408.2,90.8 400.7,78.5 334.4,77.2 334.4,95.8 311.5,95.8 
              "/>
          
            <image className="state-image" width="211" height="250" xlinkHref={data.chihuahua.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 299.31 72.775)">
          </image>
        </g>
        <g className="state" id="sinaloa">
          <path class="st0" d="M423,445.5l8-1.6l-0.4-4.9h-4.5l0.4-5.4l4.3-5.3l-1.1-2l1.2-4h2.6l-0.5-1.3h-4.2l-7-5.8v-7.9l-6.4-10.2
            l-2.4-13.4v-4.6l-10-7l-5.7,4.2l-6-2l-1.9-9.3l-10.9-10.4l-8.2-19.3l2.5-14l-6.8-10l-1.7-6.9l-16.5-4.4l-1.8-18.3l-7.5-5.4v-6.9
            l-8.4-2.9l-5.7,2.8v6l-24.4,17.8c0.2,0.3,0.4,0.5,0.6,0.5c0.5,0,0.4,2.9,0.4,2.9l-2.3-1l-0.5-1l-0.9,0.6l-1.4,3.4l-4,0.9l-3.2,7.9
            l2.4,4.2l-2.2,2.3l1.4,1.3l5,2.8l3.2,5.1l2.8,0.4l2.2-1.8l3.2-2.3l4.1-4.1v2.4l-3.8,6l-2.8,1.3l-1.8,1.9l2.7,0.5l2.7-0.9l1.7-1.7
            l4.2,1.1l0.4,2.9l11.5,7.4l1.8-0.4c0,0,2.7,0.3,3.1,0.3s-0.3,2.2-0.3,2.2l1.8,1.7l5.9,2l2.5,2.9l0.4,6.6l0.6,2.7l-1,2.4l-0.3,4.1
            l4,1.4l5.9,2.7l2.4,3.6l3.8-1.9l1.5,1.1l1.4,4l-0.5,1.7l20.9,18.6l17.9,24.9l22.2,23.4L423,445.5z"/>
          
            <image className="state-image" width="151" height="181" xlinkHref={data.sinaloa.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 286.33 264.0615)">
          </image>
        </g>
        <g className="state" id="coahuila">
          <polygon class="st0" points="513.8,186.5 487.6,234.3 500.8,281.7 513.4,295.1 509.9,305 511.5,316.4 505.6,326.1 507.2,331 
            505.2,333.9 508.2,338.8 513.2,341.9 513.2,349.7 526.3,355.5 526.3,350.4 533.1,340.5 555,340.8 565.7,344.1 568,349.5 
            573.7,351.7 573.7,355.2 575.3,356 581.9,352.1 587,352.4 592.7,361.1 599.2,361.1 598.6,352.2 602.3,349.6 597.5,347 601.3,339 
            609.2,336.6 614.3,338.7 621.5,336.4 621.6,336.3 610.5,333.2 602.2,323.9 605.5,323.9 600.5,320.2 599,312.1 595.6,308 
            594.6,302.5 584,292.9 599.5,277 604.1,280.4 609,271 607.5,267.4 599.7,267.4 599.7,255.5 607.5,250.5 612.6,250.5 616.5,239.2 
            621.6,235.9 630,240.3 636.7,234.5 632.6,228.7 629,220.7 621,215.7 619,206.6 612.8,197.3 605.4,183.5 602.6,181.4 602.6,176.9 
            594,171.9 592.6,168.9 587.2,166.4 586,162.8 583.1,161.7 579.5,156.2 574.2,153.7 571.5,156.2 553.8,153.7 546.1,151.4 542.8,156 
            530.6,157.4 526.5,171.4 524,172.8 524,179.4 520.2,180.1 514,186.7 	"/>
          
            <image className="state-image" width="151" height="212" xlinkHref={data.coahuila.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 485.5 149.99)">
          </image>
        </g>
        <g className="state" id="tamaulipas">
          <polygon class="st0" points="647.6,240.7 645.3,240.7 633.5,246.8 634.2,249.5 639.2,249.5 641.7,263.1 640,271.3 645.9,271.3 
            645.4,279.2 649.7,279.2 653.9,287.3 650,290.1 651.7,293.4 654.7,293.4 660.9,295.2 660.9,302.9 665.1,301.7 668.3,304.7 
            671.1,301.9 682.5,305.5 682.5,327 686.6,327 686.6,331.1 668.3,345.9 663,343.3 659.9,345.1 659.9,354.9 640.4,364.3 641.4,367.2 
            644.5,367.2 647.6,373 644.5,376.9 644.8,383.1 650.6,388.9 645.5,395 635.6,397.7 632.3,403.5 634.8,413.5 628.8,411 628.2,414.4 
            629.1,414.4 629.1,419.7 635.9,424.8 633.2,430.3 629.5,432 630.4,434.8 643.7,440.7 645.9,436.8 652.8,438.2 656.5,445.9 
            669.3,450 678.3,448.5 689.9,448.5 689.9,446.4 695.1,445.6 697.3,448.7 704.3,450.3 704.3,453.9 706.2,453.9 708.5,452.4 
            705.9,443.1 705.8,438.1 709.5,431.1 709.7,415.3 711,386 714.9,363.5 714.4,363 711.6,377.4 711.3,368.9 707.9,369.6 706.9,366.2 
            709.1,364.7 710.3,366.9 711.7,366.9 712.6,359.3 712.4,356 711.6,352.7 714.5,352.2 714.8,349.5 709.4,348 711.9,346.4 711,336.7 
            713.9,332.6 718.1,334.7 719.1,328.7 722.2,329.7 722.1,334.5 718.4,346.4 719,347.5 722.6,335.9 732.1,319.1 732.1,308.4 
            727.8,307.5 722.3,310 715.1,304.1 692.4,302.3 687.8,297.7 678.3,295.5 674.9,291.6 664,288.9 664,286.6 660.5,284.1 660.5,279.3 
            650.3,265.5 650.3,255.7 649,253.9 650.3,250.5 	"/>
          
            <image className="state-image" width="109" height="217" xlinkHref={data.tamaulipas.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 626.08 238.6541)">
          </image>
        </g>
        <g className="state" id="durango">
          <polygon class="st0" points="476,420.4 481.2,417 481.2,405.9 489.7,398.3 487,394.4 489.8,381.9 497.5,378.6 496,374.8 510.3,367 
            537.6,367 537.6,354.2 532.5,345.9 528.8,351.2 528.8,359.4 510.6,351.4 510.6,343.3 506.4,340.7 502.2,333.8 504.4,330.6 
            502.8,325.8 508.8,315.9 507.3,304.8 510.5,295.7 498.9,283.5 478.9,277.9 467.7,293.9 463,293.9 458.5,290.2 443.6,287 
            437.2,289.9 426.3,282.7 420.1,281.2 412.2,275.8 409.1,277 409.1,282.8 405.1,292.8 397.5,292.8 397.7,294 402.6,298.7 
            400.6,303.4 395.7,306.6 395.7,317 388.3,324 375.2,324 373.2,335 380.9,353.1 392,363.7 393.8,372.4 397,373.5 403.2,369 
            415.7,377.8 415.7,383.5 418,396.2 424.5,406.6 424.5,414.1 429.8,418.5 434.8,418.5 436.5,422.6 452.1,427.2 456,436 449.3,438.2 
            449.2,441 456.8,437.1 462.7,440.3 462.7,445.8 465.9,448.3 470.4,446.4 472.8,450.3 476,446.3 	"/>
          
            <image className="state-image" width="166" height="177" xlinkHref={data.durango.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 373.21 272.57)">
          </image>
        </g>
        <g className="state" id="nayarit">
          <polygon class="st0" points="451,506 458.3,502.6 461.8,506.1 465.7,507.4 473.6,515.1 476.5,505.5 476.5,498.8 485.5,494.4 
            485.5,489.3 475.9,486.7 479.9,474.3 467.7,463.1 473,455.2 469.4,449.6 465.6,451.3 460.1,447.1 460.1,441.8 456.8,440 
            446.4,445.3 446.8,436.3 452.5,434.4 450.2,429.3 435.3,424.9 432.9,424.9 432.5,426.1 434,428.7 429,434.7 428.9,436.5 
            433.1,436.5 433.8,446 424.4,447.8 425.3,449.1 425.5,466.5 431.4,474.4 433.8,481.9 441.5,484.6 442,487.6 440.7,489.6 
            441.9,502.5 438.1,503.9 430.1,513.2 432.9,514.9 437.3,513.5 438.5,515.5 438.5,515.6 445,507.4 	"/>
          
            <image className="state-image" width="70" height="94" xlinkHref={data.nayarit.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 420.43 422.56)">
          </image>
        </g>
        <g className="state" id="nuevo_león">
          <polygon class="st0" points="614.2,341.4 609.1,339.3 603.1,341.1 600.8,345.9 607.2,349.4 601.2,353.5 602,364.3 608.9,372.9 
            608.9,389.4 615,393.7 615,398.4 613.5,400.6 615,410.5 615,416.2 615.7,416.2 617,414.7 625.5,414.5 626.9,407.5 631.1,409.2 
            629.6,403.2 633.9,395.5 644.1,392.8 647.2,389.1 642.3,384.2 642,376 644.5,372.7 643,369.8 639.5,369.8 637.3,363 657.4,353.3 
            657.4,343.7 663,340.4 668,342.9 684,329.9 684,329.5 679.9,329.5 679.9,307.4 671.8,304.8 668.4,308.2 664.5,304.5 658.4,306.3 
            658.4,297.1 654.3,295.9 650.2,295.9 646.7,289.4 650.6,286.6 648.1,281.8 642.7,281.8 643.2,273.8 636.8,273.8 639.1,263.1 
            637.1,252 632.3,252 630.5,245.5 641.8,239.6 638.8,237.3 638.2,236.5 630.3,243.3 621.8,238.8 618.7,240.8 614.4,253.1 
            608.2,253.1 602.2,256.9 602.2,264.9 609.2,264.9 611.8,271.1 605,284.2 599.8,280.4 587.6,292.8 597,301.2 598,306.9 601.3,311 
            602.9,318.8 613.3,326.4 607.9,326.4 611.9,330.9 624.9,334.6 623.5,338.5 	"/>
          
            <image className="state-image" width="109" height="182" xlinkHref={data.nuevo_leon.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 579.03 233.1101)">
          </image>
        </g>
        <g className="state" id="zacatecas">
          <polygon class="st0" points="574.5,469.9 581.1,461.3 577.8,449.6 580.4,444.8 574.4,442.5 566.2,448.4 560.2,445.9 558.3,441.7 
            551.8,437.9 544.8,428.2 548.7,423.5 544.9,415.4 549.6,410.8 553.8,410.8 561.3,404 577.9,388.7 591.8,370.4 599,364.6 
            598.3,363.6 591.3,363.6 585.6,354.9 582.5,354.7 575.3,358.9 571.2,356.7 571.2,353.5 566.1,351.5 563.8,346.2 554.6,343.4 
            534.4,343.1 534,343.6 540.1,353.5 540.1,369.5 511,369.5 499.2,376 500.8,379.9 492,383.8 489.7,393.9 493.1,398.7 483.7,407 
            483.7,418.4 478.6,421.8 478.6,447.2 474.3,452.5 474.3,452.6 483.5,451.3 485.3,445.8 482.3,442.5 484.2,433.1 494,433.1 
            494,440.9 491,442.1 491.4,446 490.6,452.8 490.8,453.2 491.5,452.3 494.6,441.8 501.4,443.8 497.9,459.3 503.9,459.3 507.2,452.5 
            504.5,447.8 504.5,443.4 516,449.1 515.2,451.8 520.7,454.7 517.4,460 520.8,461.6 515.1,469 508.8,469 502.3,474 504.5,478.2 
            504.5,485.8 498.4,488.4 496.3,487.8 497.8,490.7 495.1,494.7 498.8,494.7 517.6,501.8 517.6,491.9 530.7,488.7 532.7,483.9 
            528,480.7 528.8,475.5 523.8,468.8 533.7,460.7 533.7,454.4 539.7,451.4 545.9,445.7 549.1,445.7 549.1,451.5 558.4,454.4 
            556.2,460.3 559.8,461.5 563.9,463.8 	"/>
          
            <image className="state-image" width="129" height="163" xlinkHref={data.zacatecas.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 472.08 339.82)">
          </image>
        </g>
        <g className="state" id="jalisco">
          <polygon class="st0" points="464.7,573.3 479.9,569.4 479.9,565.8 482.4,562.8 491.3,567.7 499.1,565.3 504.7,569.6 502.2,578.7 
            503,585 508,584.1 509.8,580.5 517,583.8 521.2,581.6 518.9,577.7 533.2,574.9 534.8,568.7 534.8,567 528.4,567 527.6,553.7 
            529.1,550.6 523.5,549 518.6,553.5 515.5,540.5 536,534.6 540.8,531.1 546.2,531.1 553.6,529.3 556.6,522.5 552.2,516.1 
            562.3,497.2 569.1,495.1 571.3,491.6 568.2,485.8 572.6,478.9 571,474.5 573,471.9 563.4,466.5 548.8,480.4 543.7,479.8 
            534.1,475.5 531.3,476.2 530.8,479.5 535.8,482.9 532.6,490.9 520.1,493.9 520.1,505.5 498.4,497.2 490.3,497.2 494.8,490.5 
            491.2,483.8 498.2,485.7 501.9,484.1 501.9,478.8 499,473.3 507.9,466.5 513.9,466.5 516.9,462.6 513.7,461.1 517,455.7 
            512.2,453.1 513,450.5 507.3,447.7 510,452.4 505.5,461.9 494.7,461.9 498.4,445.6 496.4,445 493.8,453.5 489.5,458.7 488.1,453 
            488.8,446 488.2,440.5 491.4,439.2 491.4,435.7 486.3,435.7 485.1,441.7 488.2,445.1 485.5,453.6 475.9,455 476,455.2 471,462.7 
            482.9,473.5 479.2,484.9 488.1,487.3 488.1,496 479.1,500.4 479.1,505.9 474.8,519.9 464.3,509.7 460.5,508.4 457.8,505.7 
            451.8,508.5 446.5,509.7 439.8,518.2 441.1,521 433.9,524.8 430.6,524.8 424.4,528.9 428.8,535.2 428.4,541.1 439.9,559.7 
            445.5,561.6 446.1,567.4 449.5,571.8 456,572.2 456.3,575.4 458.8,575.4 462.4,576.8 	"/>
          
            <image className="state-image" width="161" height="149" xlinkHref={data.jalisco.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 424.6111 435.8022)">
          </image>
        </g>
        <g className="state" id="aguascalientes">
          <polygon class="st0" points="541.1,453.5 536.3,456 536.3,462 527.3,469.3 530.7,473.8 534.4,472.8 544.3,477.3 547.9,477.7 
            561.1,465.2 558.8,463.8 552.8,461.9 555.1,456 546.6,453.4 546.6,448.6 	"/>
          
            <image className="state-image" width="35" height="31" xlinkHref={data.aguascalientes.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 527.695 447.26)">
          </image>
        </g>
        <g className="state" id="san_luis_potosi">
          <polygon class="st0" points="593.6,372.2 579.8,390.4 563.1,405.9 554.7,413.4 550.7,413.4 548,416 551.6,423.8 548,428.3 
            553.5,436 560.3,439.9 562.1,443.9 565.9,445.5 574.1,439.6 584,443.5 580.5,450 583.9,461.9 577.3,470.5 581.3,470.5 585.6,475.6 
            594.4,475.6 606.8,483.2 615.8,477.7 622.6,478.9 636.4,486.3 636.6,482.6 642.5,479.6 646.2,486.7 648,486 652.1,479.6 
            655.5,481.7 658.2,477.1 663.1,492.1 670.4,491.8 670.4,496.8 676.6,495.7 676.6,490.6 681.9,487.6 681.8,486.2 678.4,482.3 
            678.7,477 683.4,474.1 685.1,473.3 680.2,469.4 686,455.7 678.1,451.1 669.1,452.7 654.6,448 651.1,440.4 647.3,439.7 644.8,444 
            628.4,436.6 626.3,430.7 631.3,428.3 632.7,425.6 626.6,420.9 626.6,417 618.2,417.2 616.9,418.8 612.5,418.8 612.5,410.7 
            610.9,400 612.5,397.6 612.5,395.1 606.3,390.7 606.3,373.8 600.6,366.6 	"/>
          
            <image className="state-image" width="141" height="133" xlinkHref={data.san_luis_potosi.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 546.475 365.9133)">
          </image>
        </g>
        <g className="state" id="guanajuato">
          <polygon class="st0" points="582.3,543.5 585.7,541.1 596.7,542.5 598.5,546.5 604.5,544.4 610.7,546.5 617.5,537.6 612,530.8 
            611.5,520.6 605.5,517.3 610.3,506 620.9,506 625.3,507.5 625.3,503.3 628.8,497.1 637.2,495.5 637.6,495.3 635.8,494.5 
            636.2,489.1 621.8,481.4 616.3,480.4 606.8,486.2 593.6,478.2 584.4,478.2 580.1,473 575.3,473 573.9,474.9 575.4,479.2 
            571.1,485.9 574.2,491.7 570.7,497.3 564.1,499.3 555.2,515.9 559.5,522.3 556.3,529.5 560.7,530.7 560.6,536.8 568.3,536.8 
            571.9,532.9 578.4,532.9 580.8,538 579,542.2 	"/>
          
            <image className="state-image" width="83" height="75" xlinkHref={data.guanajuato.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 554.5741 471.5059)">
          </image>
        </g>
        <g className="state" id="queretaro">
          <polygon class="st0" points="625.2,543.2 630.7,539.9 630.7,533.9 635,533.9 635,524.1 647.5,519.1 645.7,514.7 648,509.4 
            650.2,506.8 650.2,501 655.5,497 661.5,497 661,493.7 657.5,483.2 656.4,485.2 652.9,483.1 649.7,488.1 644.9,489.9 641.4,483.1 
            639.1,484.2 638.4,492.9 643.3,495.1 638,498 630.5,499.4 627.9,504 627.9,511.1 620.5,508.5 612,508.5 608.7,516.2 614,519 
            614.5,529.9 620.8,537.5 620.8,537.5 	"/>
          
            <image className="state-image" width="54" height="62" xlinkHref={data.queretaro.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 607.5 481.77)">
          </image>
        </g>
        <g className="state" id="hidalgo">
          <polygon class="st0" points="682.6,490.2 679.2,492.1 679.2,497.8 667.9,499.8 667.9,494.5 663.7,494.6 664.5,499.6 656.4,499.6 
            652.7,502.3 652.7,507.7 650.2,510.8 648.5,514.7 650.8,520.5 637.6,525.8 637.6,534.7 641,538.6 647.7,538.6 649.8,543.2 
            648,546.5 651.8,549.9 652.9,553.7 655,553.7 656.8,544 666.4,541.8 670.2,544.6 667.7,551.5 668.4,551.9 670.5,549.8 680.2,551 
            683.2,556.7 680.4,560.2 683.9,558.8 688.5,559.7 691,555 691.9,555 689.3,553.1 694.7,546.9 696.8,541.1 696.1,538.5 689.9,536.1 
            689.9,532 694.9,530.3 696.9,526.6 698.3,520.3 697,519.1 689.3,528.5 682.5,532.8 679.8,524.3 683.8,517.4 680.7,513.6 
            684.6,511.8 689.2,508.1 692.5,511.3 693.3,504.5 695.2,501.8 694,500.8 692.3,501.6 689.4,499.1 689.4,502 682.5,497.2 
            683.9,493.6 	"/>
          
            <image className="state-image" width="62" height="70" xlinkHref={data.hidalgo.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 636.27 489.7837)">
          </image>
        </g>
        <g onClick={to} className="state" id="tlaxcala">
          <path class="st0" d="M698.7,557.5 692.5,557.5 689.8,562.6 684.2,561.4 678.9,563.6 678.4,562.4 678.1,562.7 679.5,566 
            686.8,566 695.5,579.9 700.8,576.4 706.1,578.5 706.9,577.2 706.9,573.7 712,571.2 708.2,568.3 703.2,564.9 704.3,562.7z 	"/>
          
          <image className="state-image" xlinkHref={data.tlaxcala.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 677.97 556.53)">
          </image>
        </g>
        <g className="state" id="distrito_federal">
          <polygon className="st0" points="659.6,564.1 658.5,569.8 653.6,571.6 656.2,578.8 660.4,578.8 666.4,580.1 667.3,575.9 663.3,571.8 
            663.3,565.4 	"/>
          
            <image className="state-image" width="18" height="18" xlinkHref={data.distrito_federal.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 651.1481 564.105)">
          </image>
        </g>
        <g className="state" id="morelos">
          <polygon className="st0" points="658.6,601.8 665.2,606.5 670.8,602.9 674.3,606 676.4,606 672.1,596.7 676.3,594.2 673.8,591.7 
            677.1,588.1 677.1,584.8 673.4,586.4 666.9,582.8 660.2,581.4 656.7,581.4 657.4,587.3 650.7,590.4 647.8,598.7 652.4,603.8 	"/>
          
            <image className="state-image" width="32" height="27" xlinkHref={data.morelos.childImageSharp.fluid.src}  transform="matrix(1.0893 0 0 1.0893 645.5344 579.2596)">
          </image>
        </g>
        <g className="state" id="estado_de_mexico">
          <polygon className="st0" points="620.9,576 611.5,585.4 610.5,588.9 613.8,590.8 618.5,606 620.9,606 623.9,599.6 635.4,595.8 
            637.3,596.8 642,592.4 645.8,596.6 648.6,588.5 654.6,585.7 654,580.4 650.3,570.1 656.2,567.9 657.7,560.7 665.8,563.5 
            665.8,570.7 670,575.1 668.8,581 673.6,583.6 677.1,582.1 677.4,567.5 675.1,562.1 677.7,559.5 680.2,556.4 678.6,553.3 
            671.4,552.5 668.9,555 664.5,552.8 667.1,545.5 665.8,544.5 659,546.2 657.1,556.2 651,556.2 649.6,551.3 644.7,547 646.9,543.1 
            646,541.1 639.8,541.1 635.8,536.5 633.2,536.5 633.2,541.3 626.7,545.3 629.7,549.1 625.1,552.4 623,559.1 625.5,568 620.9,571.7 
              "/>
          
            <image className="state-image" width="94" height="73" xlinkHref={data.estado_de_mexico.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 597.41 533.75)">
          </image>
        </g>
        <g className="state" id="michoacán">
          <polygon className="st0" points="551.5,617.5 560.2,613.4 560.2,600.3 570.8,598.2 574.2,603.7 583.2,603.2 593,603.2 595.8,606 
            599.1,606 606.2,609 606.8,607.7 601.5,603.9 599.5,588.6 602.9,588.6 605.7,590.8 607.4,590.5 609.3,584 618.4,575 618.4,570.5 
            622.6,567.1 620.4,559 622.9,550.8 626,548.6 619.2,539.6 611.7,549.5 604.5,547.1 597.2,549.6 594.9,544.9 586.4,543.7 
            582.6,546.4 575.7,543.6 578,538.1 576.8,535.5 573,535.5 569.4,539.3 558,539.3 558.1,532.6 554.5,531.7 546.6,533.7 541.6,533.7 
            537.1,536.9 518.6,542.3 520.1,548.7 522.9,546.1 532.7,549 530.2,554.2 530.8,564.5 537.3,564.5 537.3,569 535.3,577.1 
            522.9,579.5 524.7,582.7 517.1,586.7 511,583.8 509.7,586.4 502.6,587.7 499.9,590.6 499.9,594.4 494.5,598 494.7,598.3 
            495.9,600.3 498.3,602.2 501.5,607.2 504.4,611.8 528.9,620.9 534.3,622.2 547.6,626.9 550.3,627.3 	"/>
          
            <image className="state-image" width="134" height="97" xlinkHref={data.michoacan.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 494.11 529.51)">
          </image>
        </g>
        <g className="state" id="colima">
          <polygon className="st0" points="492.7,596.1 497.3,593 497.3,589.6 500.6,586.1 499.6,578.5 501.8,570.6 498.6,568.2 491,570.5 
            483,566.1 482.4,566.7 482.4,571.3 466.3,575.6 464.8,577.8 473.4,581.2 473.5,584.1 488.5,591.4 	"/>
          
            <image className="state-image" width="42" height="32" xlinkHref={data.colima.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 463.52 564.38)">
          </image>
        </g>
        <g className="state" id="guerrero">
          <polygon className="st0" points="680.7,690.1 682,688.5 687.6,686.9 687.6,682.4 691.3,680.6 691.8,673.2 698,673.2 701.5,662.5 
            689.4,655.2 689.4,648 687.9,642.6 687.9,628.6 683.7,624.2 671.7,624.2 662.8,614.5 662.8,607.9 658.2,604.6 651.6,606.7 
            641.9,596 637.7,599.9 635.2,598.6 625.8,601.7 622.5,608.6 616.7,608.6 611.7,592.5 609.8,591.4 609.4,592.8 604.9,593.5 
            602.4,591.5 603.9,602.5 610,606.9 607.5,612.3 598.6,608.6 594.8,608.6 591.9,605.8 583.3,605.8 572.8,606.3 569.5,601 
            562.7,602.4 562.7,615 553.8,619.2 553,626.1 553.3,625.9 556.7,624.8 560,625.7 565,629.6 568.7,634.9 572.6,639 575.4,639.2 
            576.8,642.3 587.6,648.3 592.3,653.7 627.7,667 633.3,669 635.3,673 642.7,676 662.5,679.8 669.3,682.7 673.9,681.4 680,689.5 	"/>
          
            <image className="state-image" width="148" height="100" xlinkHref={data.guerrero.childImageSharp.fluid.src}  transform="matrix(1.0231 0 0 1.0214 550.2963 589.4)">
          </image>
        </g>
        <g className="state" id="veracruz">
          <polygon className="st0" points="730.7,512.5 721,491.2 714,482.9 712.6,470.6 710,465.6 709,455.1 707,456.5 701.7,456.5 701.7,452.3 
            695.8,450.9 694,448.4 692.5,448.6 692.5,451.1 683.1,451.1 689.2,454.7 683.3,468.6 690.1,474 684.6,476.3 681.2,478.5 681,481.4 
            684.2,485.2 684.5,488 686.7,493.7 685.6,496.3 686.9,497.1 686.9,493.4 692.7,498.6 694.5,497.8 698.5,501.4 695.8,505.4 
            694.5,516.8 689.1,511.5 686,514 684.8,514.6 686.8,517.1 682.6,524.6 684,528.9 687.5,526.6 696.7,515.4 701.1,519.4 699.9,524.8 
            701.6,524.1 702.3,519.3 704.3,516.6 704.3,511.2 711.2,513.1 711.2,518.9 718.5,521.3 716.7,532.5 712.5,528.9 711.2,530 
            713.1,536.6 715.5,538.8 723.5,534 734.3,540 726.5,548.3 730,557 723.4,561.8 727,569 738.1,571.9 738.1,578.8 729.3,578.8 
            727.6,591.4 725.6,593.7 727.3,597.1 733,595.8 735.8,602.9 744,600.7 746.2,605.2 749.2,602.4 749.2,595.5 757,599.4 764,611.2 
            771.8,615.6 779.3,618.6 783.5,625.2 779,633 782.7,642.7 800.8,633.7 804.5,638.1 803,641.7 810.7,650.1 810.7,654.3 851.1,656.5 
            858.9,650.5 853.1,637.4 847.8,634.5 839.4,628.4 841.9,624.5 839.4,616.3 830.1,618.6 824.8,616.8 823.4,611.7 819.2,608.2 
            816.8,602.8 809.9,602.8 803.8,596.1 789.3,597.1 782,593.7 776,589.3 774,582.1 770.8,582.1 768.9,576.2 762,571.2 762,566 
            760.1,564.6 760.1,561.8 758.2,551.7 730.7,516.1 	"/>
          
            <image className="state-image" width="180" height="209" xlinkHref={data.veracruz.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 679.9449 447.605)">
          </image>
        </g>
        <g className="state" id="puebla">
          <path className="st0" d="M719.3,624.4 721.1,624.1 725,616 732.2,615.8 738.9,617.8 742,613.3 744.1,612.9 744.5,607.5 
            742.6,603.7 734.3,606 731.5,598.8 725.9,600 722.5,593.2 725.1,590.3 727,576.3 735.6,576.3 735.6,573.9 725.2,571.2 720.1,561 
            726.8,556.1 723.6,547.8 730.2,540.7 723.5,537 715.2,541.9 710.9,538 708.2,529.1 712.5,525.6 714.9,527.7 715.7,523 708.7,520.8 
            708.7,515.1 706.8,514.6 706.8,517.4 704.7,520.3 703.9,525.8 699.1,528 696.7,532.4 692.5,533.8 692.5,534.3 698.2,536.6 
            699.5,541.2 697,548.2 693.1,552.7 696.2,555 699.7,555 707.4,562.1 706.4,564 709.7,566.3 714.5,570 714.5,572.8 709.4,575.3 
            709.4,577.9 707.1,581.7 701.1,579.2 694.7,583.4 685.4,568.6 679.9,568.6 679.7,582.9 679.7,589.1 677.3,591.6 680.5,594.7 
            675.4,597.7 680.4,608.6 673.3,608.6 670.6,606.1 665.3,609.5 665.3,613.5 672.8,621.7 684.8,621.7 689.7,626.8 692,626.8 
            694.6,622.2 701.8,622.2 705.6,626.5 708.1,625.1 706.3,619.3 710.1,609.3 716.1,609.3 716.1,619z"/>
          
            <image className="state-image" width="85" height="117" xlinkHref={data.puebla.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 662.2225 512.8022)">
          </image>
        </g>
        <g className="state" id="oaxaca">
          <polygon className="st0" points="839.7,695 842.5,697.5 842.8,696.9 843.3,689.6 839.6,682 843.5,679.3 842.2,671.4 847.3,667.6 
            849.8,659 808.2,656.7 808.2,651.1 800,642.2 801.6,638.5 800.1,636.8 781.3,646.3 776.2,632.8 780.5,625.3 777.6,620.6 
            770.7,617.9 762.1,613.1 755.2,601.3 751.7,599.6 751.7,603.5 747.1,607.9 746.5,615 743.5,615.6 740,620.8 731.8,618.3 
            726.7,618.5 722.8,626.4 718,627.2 713.5,619.7 713.5,611.9 711.8,611.9 709,619.4 711.2,626.4 705,629.7 700.6,624.7 696.1,624.7 
            693.5,629.3 690.4,629.3 690.4,642.3 692,647.6 692,653.8 704.5,661.3 699.8,675.8 694.2,675.8 693.7,682.2 690.2,684 690.2,688.8 
            683.5,690.7 682.5,691.8 683.3,692.5 689.5,692.8 698,696.1 710.3,705.4 728.9,706.3 743.7,714 748,714.5 751.9,716.8 754.8,717.4 
            757.9,715.4 766.5,716.2 770.7,713.5 796.2,704.2 797,701.1 804.5,696.5 811.2,696.2 811.5,695 807.1,693.3 808,691 810.8,690.9 
            812.1,688.3 814.8,686.7 817.8,691 819.1,691.1 822.7,689.2 824.8,691 824.2,693.3 820.4,693.5 821,695.5 829.6,696.7 830.5,694.8 
            832,691.8 837.2,695.1 	"/>
          
            <image className="state-image" width="167" height="135" xlinkHref={data.oaxaca.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 682.4449 593.8022)">
          </image>
        </g>
        <g className="state" id="chiapas">
          <polygon className="st0" points="968.4,683 968.6,680.1 964.9,673.2 958.5,671.5 956.2,667.5 952.3,667.5 951,662.8 947.9,662.8 
            945.9,658.2 939,657.4 939,652.1 935.3,649.8 933.2,647.4 929.4,643.1 927.3,636.4 923.3,631.4 918.5,629.6 912.6,635.5 
            906.6,635.5 888,651.1 886.2,643.8 881,643.8 881,630.6 877,630.6 872.7,627.7 868.2,632 868.2,640.6 861,649 862.1,651.3 
            852.6,658.6 849.5,669.1 845,672.5 846.2,680.5 842.9,682.8 845.9,689.1 845.3,697.7 845.1,698.1 848.1,698.8 850.4,701 
            850.4,704.5 873,722 877.6,728.4 886.8,734.5 909.3,762.1 911.8,755.1 911,750.6 913,748 912.1,743.7 915.5,740.2 910,733.7 
            926.7,701 975,701 974.9,696 977.1,687.5 	"/>
          
            <image className="state-image" width="141" height="135" xlinkHref={data.chiapas.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 841.7222 625.3022)">
          </image>
        </g>
        <g className="state" id="tabasco">
          <polygon className="st0" points="844.7,624.9 842.9,627.8 849.1,632.4 855.1,635.6 859.9,646.4 865.6,639.7 865.6,630.8 872.4,624.4 
            877.8,628.1 883.6,628.1 883.6,641.2 888.2,641.2 889.5,646.5 905.7,632.9 911.5,632.9 917.9,626.6 924.9,629.3 929.6,635.2 
            931.7,641.9 935.1,645.7 937,647.8 941.5,650.7 941.5,655.1 944.7,655.5 943.7,653.3 954.1,653.3 954.1,631.5 952.6,627 
            935.2,621.2 932.3,621.3 932.3,625.2 922.8,625.2 909.7,618.5 909.7,606 902.4,606 900.1,599.2 893,600.1 893,605.8 892.1,601.5 
            885.4,606.8 878.2,607.2 877.6,609.7 876.2,610.2 874.6,608 867.1,607.3 861.6,608.2 858.3,612.3 854.3,612.5 853.7,613.3 
            850.8,613.7 849.6,613.2 844.9,615 841.9,615.7 	"/>
          
            <image className="state-image" width="117" height="63" xlinkHref={data.tabasco.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 838.465 595.4689)">
          </image>
        </g>
        <g className="state" id="campeche">
          <polygon className="st0" points="904.2,603.5 912.3,603.5 912.3,616.9 923.4,622.7 929.8,622.7 929.8,618.9 935.6,618.6 954.6,625 
            956.5,630.6 957.4,631.4 1008.5,631.4 1008.5,558.2 986.2,527.3 982.6,528.7 980.2,522.4 974.4,522.4 974.4,515.7 973.5,517.8 
            971.7,542.3 973.5,545.3 973.5,547.2 964.9,554.3 962,571.6 943.2,586.5 939.8,590.5 940,592.8 942.8,592.9 944.1,595.6 944,598.2 
            945.9,599.4 935.8,603.4 935,606.1 926.5,606.6 924.8,609.3 922.9,608.2 921.5,604 915.4,601.9 917.4,599.6 921.5,599.3 
            919.2,595.6 902.7,598.8 	"/>
          
            <image className="state-image" width="108" height="116" xlinkHref={data.campeche.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 902.04 515.3022)">
          </image>
        </g>
        <g onClick={to} className="state" id="Quintana_roo">
          <polygon className="st0" points="1104.1,499.3 1104.1,488.6 1100.7,487.9 1095.6,480.4 1092.3,480.5 1091.5,481.9 1092.9,482.8 
            1092.1,485.9 1089.3,487.9 1078.5,486.3 1078.5,503.7 1071.1,520.2 1070.8,520.4 1011.8,560.1 1011,559.1 1011,631.4 1020.2,631.4 
            1020.3,626 1025.7,624 1030.9,628.2 1044.5,604.9 1051.6,605 1055,598.5 1054.2,596 1056,595 1060.4,589.5 1061.2,596.4 
            1058.5,603.6 1064.2,606.5 1066.3,611.4 1068.2,612.3 1070.9,609.1 1071.6,598.5 1074.8,593.6 1075.8,584.4 1080.6,573.6 
            1078.3,572 1074.4,576.6 1073,575.3 1073.2,573.4 1075,568.4 1079.1,568 1082.2,565.1 1082,563 1076.8,563 1074.6,564.4 
            1070.7,560.6 1071.2,557.8 1074.6,557.7 1076.2,553.2 1080,552.3 1080.9,549.8 1080.6,539.5 1090.6,524.2 1094.9,521.8 
            1101.7,512.2 1107.2,500.1 	"/>
          
            <image className="state-image" width="98" height="154" xlinkHref={data.qroo.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 1009.49 478.3819)">
          </image>
        </g>
        <g className="state" id="yucatan">
          <polygon className="st0" points="976.9,519.8 981.9,519.8 984.1,525.4 987.1,524.2 1011,557.4 1011,557.5 1069,518.5 1075.9,503.1 
            1075.9,486 1069.5,485.5 1061.2,480.9 1056.6,481 1051.3,483.3 1048.6,482.7 1040.3,483.7 1036.4,488.2 1029.2,490 995.7,494.2 
            978.8,503.3 976.9,509.6 976.9,509.6 	"/>
          
            <image className="state-image" width="114" height="92" xlinkHref={data.yucatan.childImageSharp.fluid.src}  transform="matrix(1 0 0 1 972 470.8917)">
          </image>
        </g>
      </svg>
    </div>
    <div style={{backgroundColor: "rgba(231,225,211, 0.5)"}}>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div style={{borderRight: "1px solid #d9b777"}} className="col-12 col-md-3 d-flex">
            <div style={{width: "120px", marginRight: "10px"}}>
              <Img fluid={data.botella.childImageSharp.fluid} />
            </div>
            <div>
              <h4 className="mb-0 advantages">SELECCIÓN ÚNICA</h4>
              <p className="advantages" style={{fontSize: ".8rem"}}>
                Descubre productos nuevos y 
                exclusivos. ¡Tenemos más de 100 cervezas!
              </p>
            </div>
          </div>
          <div style={{borderRight: "1px solid #d9b777"}} className="col-12 col-md-3 d-flex">
            <div style={{width: "120px", marginRight: "10px"}}>
              <Img fluid={data.barrio.childImageSharp.fluid} />
            </div>
            <div>
              <h4 className="mb-0 advantages">CLUB CHELERO</h4>
              <p className="advantages" style={{fontSize: ".8rem"}}>
                Envío a patir de 24 cervezas por caja.
              </p>
            </div>
          </div>
          <div style={{borderRight: "1px solid #d9b777"}} className="col-12 col-md-3 d-flex">
            <div style={{width: "120px", marginRight: "10px"}}>
              <Img fluid={data.box.childImageSharp.fluid} />
            </div>
            <div>
              <h4 className="mb-0 advantages">ENVÍOS</h4>
              <p className="advantages" style={{fontSize: ".8rem"}}>
                Fácil, rápido,  hasta la puerta de tu casa  y en zonas seleccionadas
              </p>
            </div>
          </div>
          <div className="col-12 col-md-3 d-flex">
            <div style={{width: "120px", marginRight: "10px"}}>
              <Img fluid={data.calidad.childImageSharp.fluid} />
            </div>
            <div>
              <h4 className="mb-0 advantages">FÁCIL Y SEGURO</h4>
              <p className="advantages" style={{fontSize: ".8rem"}}>
                Aceptamos todos los
                métodos de pago.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 className="main-title mt-5 mb-5 text-center">Tipos de cervezas</h2>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className=" col-12 col-md-4">
            <div className="d-flex pt-3" style={{backgroundImage: `url(${data.fondo.childImageSharp.fluid.src})`}}>
              <div className="p-3" style={{width: "40%", marginRight: "10px"}}>
                <Img style={{height: "100%"}} fluid={data.lager.childImageSharp.fluid} />
              </div>
              <div style={{width: "60%"}}>
                <h4 className="advantages">Lager y Pilsen:</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>
            </div>
          </div>
          <div className=" col-12 col-md-4 d-flex">
            <div className="d-flex pt-3" style={{backgroundImage: `url(${data.fondo.childImageSharp.fluid.src})`}}>
              <div className="p-3" style={{width: "40%", marginRight: "10px"}}>
                <Img style={{height: "100%"}} fluid={data.lager.childImageSharp.fluid} />
              </div>
              <div style={{width: "60%"}}>
                <h4 className="advantages">Abadía:</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>
            </div>
          </div>
          <div className=" col-12 col-md-4 d-flex">
            <div className="d-flex pt-3" style={{backgroundImage: `url(${data.fondo.childImageSharp.fluid.src})`}}>
              <div className="p-3" style={{width: "40%", marginRight: "10px"}}>
                <Img style={{height: "100%"}} fluid={data.gueuze.childImageSharp.fluid} />
              </div>
              <div style={{width: "60%"}}>
                <h4 className="advantages">Gueuze-Lambic:</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <Img style={{width: '100%'}} fluid={data.slide.childImageSharp.fluid} />
          <Link
            to="/"
            style={{
              position: "absolute",
              bottom: "150px",
              right: "15%",
              fontSize: "40px",
              padding: ".3rem 5rem",
              cursor: "pointer",
              backgroundColor: "#253137",
              color: "#fff",
              borderRadius: "30px",
            }}
          >
            Ver más
          </Link>
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    mapBackground: file(relativePath: {eq: "fondo-mapa.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    discover: file(relativePath: {eq: "discover.png"}) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide: file(relativePath: {eq: "galeria.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    botella: file(relativePath: {eq: "beer-bottle.png"}) {
      childImageSharp {
        fluid(maxWidth: 53, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    barrio: file(relativePath: {eq: "barrio.png"}) {
      childImageSharp {
        fluid(maxWidth: 122, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    box: file(relativePath: {eq: "box.png"}) {
      childImageSharp {
        fluid(maxWidth: 106, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    calidad: file(relativePath: {eq: "calidad.png"}) {
      childImageSharp {
        fluid(maxWidth: 105, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    lager: file(relativePath: {eq: "lager.png"}) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    fondo: file(relativePath: {eq: "fondo-cerveza.png"}) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    gueuze: file(relativePath: {eq: "gueuze.png"}) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    aguascalientes: file(relativePath: {eq: "aguascalientes.png"}) {
      childImageSharp {
        fluid(maxWidth: 35, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    baja_california: file(relativePath: {eq: "baja-california.png"}) {
      childImageSharp {
        fluid(maxWidth: 176, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    baja_california_sur: file(relativePath: {eq: "baja-california-sur.png"}) {
      childImageSharp {
        fluid(maxWidth: 206, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    campeche: file(relativePath: {eq: "campeche.png"}) {
      childImageSharp {
        fluid(maxWidth: 108, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    chiapas: file(relativePath: {eq: "chiapas.png"}) {
      childImageSharp {
        fluid(maxWidth: 141, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    chihuahua: file(relativePath: {eq: "chihuahua.png"}) {
      childImageSharp {
        fluid(maxWidth: 211, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    coahuila: file(relativePath: {eq: "coahuila.png"}) {
      childImageSharp {
        fluid(maxWidth: 151, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    colima: file(relativePath: {eq: "colima.png"}) {
      childImageSharp {
        fluid(maxWidth: 41, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    distrito_federal: file(relativePath: {eq: "distrito-federal.png"}) {
      childImageSharp {
        fluid(maxWidth: 18, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    durango: file(relativePath: {eq: "durango.png"}) {
      childImageSharp {
        fluid(maxWidth: 166, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    estado_de_mexico: file(relativePath: {eq: "estado-de-mexico.png"}) {
      childImageSharp {
        fluid(maxWidth: 94, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    guanajuato: file(relativePath: {eq: "guanajuato.png"}) {
      childImageSharp {
        fluid(maxWidth: 83, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    guerrero: file(relativePath: {eq: "guerrero.png"}) {
      childImageSharp {
        fluid(maxWidth: 148, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    hidalgo: file(relativePath: {eq: "hidalgo.png"}) {
      childImageSharp {
        fluid(maxWidth: 62, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    jalisco: file(relativePath: {eq: "jalisco.png"}) {
      childImageSharp {
        fluid(maxWidth: 161, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    michoacan: file(relativePath: {eq: "michoacan.png"}) {
      childImageSharp {
        fluid(maxWidth: 134, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    morelos: file(relativePath: {eq: "morelos.png"}) {
      childImageSharp {
        fluid(maxWidth: 32, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    nayarit: file(relativePath: {eq: "nayarit.png"}) {
      childImageSharp {
        fluid(maxWidth: 70, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    nuevo_leon: file(relativePath: {eq: "nuevo-leon.png"}) {
      childImageSharp {
        fluid(maxWidth: 109, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    oaxaca: file(relativePath: {eq: "oaxaca.png"}) {
      childImageSharp {
        fluid(maxWidth: 167, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    puebla: file(relativePath: {eq: "puebla.png"}) {
      childImageSharp {
        fluid(maxWidth: 85, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    qroo: file(relativePath: {eq: "qroo.png"}) {
      childImageSharp {
        fluid(maxWidth: 105, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    queretaro: file(relativePath: {eq: "queretaro.png"}) {
      childImageSharp {
        fluid(maxWidth: 54, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    san_luis_potosi: file(relativePath: {eq: "san-luis-potosi.png"}) {
      childImageSharp {
        fluid(maxWidth: 141, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    sinaloa: file(relativePath: {eq: "sinaloa.png"}) {
      childImageSharp {
        fluid(maxWidth: 151, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    sonora: file(relativePath: {eq: "sonora.png"}) {
      childImageSharp {
        fluid(maxWidth: 235, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    tabasco: file(relativePath: {eq: "tabasco.png"}) {
      childImageSharp {
        fluid(maxWidth: 117, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    tamaulipas: file(relativePath: {eq: "tamaulipas.png"}) {
      childImageSharp {
        fluid(maxWidth: 109, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    tlaxcala: file(relativePath: {eq: "tlaxcala.png"}) {
      childImageSharp {
        fluid(maxWidth: 35, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    veracruz: file(relativePath: {eq: "veracruz.png"}) {
      childImageSharp {
        fluid(maxWidth: 180, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    yucatan: file(relativePath: {eq: "yucatan.png"}) {
      childImageSharp {
        fluid(maxWidth: 114, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    zacatecas: file(relativePath: {eq: "zacatecas.png"}) {
      childImageSharp {
        fluid(maxWidth: 129, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
